import packages from '../../inc/js/package';
// import original from '../../inc/js/original';
import 'normalize.css';
import '../css/style.scss';

// npmでインストールしたパッケージの読み込み
packages();

// オリジナルパーツ読み込み
// original();


// 新規のコードはこのカッコの中に記載してください。

// PC/SP振り分け（必要なときに使ってください。）
$(function() {
    var windowWidth = $(window).width();
    var windowSm = 768;
    if (windowWidth <= windowSm) {
        //横幅768px以下のとき（つまりスマホ時）に行う処理を書く
            // 求人用スクロールボタン（SP）
            $(function() {
                $(document).ready(function() {
                                 //queue()で処理を溜めてdequeue()で実行。2.9秒経ったらfadeOut()
                                $("#scroll_down").fadeIn().queue(function() {
                                        setTimeout(function(){$("#scroll_down").dequeue();
                                        }, 2900);
                                });
                                $("#scroll_down").fadeOut();
                });
            });
            // 教育カリキュラムのアコーディオン
            $(function(){
                $('section.curriculum #curriculum_flow > li .flow_ttl').click(function(){
                    $(this).next('ul').slideToggle();
                    $(this).toggleClass('open');
                });
            });
    
    } else {
        //横幅840px超のとき（タブレット、PC）に行う処理を書く
    }
    });
    
    
    // 求人用応募ボタンのアシガルくんダンス（PC）
    $(document).ready(function() {
    $('.oubo_btn .btn a span.inner_img').jrumble({
            x: 2, //x軸の揺れ幅
            y: 4, //y軸の揺れ幅
            rotation: 4, //角度
            speed: 100 //揺れるスピード　数字が大きいほどゆっくり
        });
    $('.oubo_btn .btn a span.inner_img').hover(function(){
    　　　$(this).trigger('startRumble');
    　}, function(){
    　　　$(this).trigger('stopRumble');
    　});
    });
    
    
    // 求人用応募ボタン（SP）
    $(function() {
            var showFlag = false;
            var topBtn = $('.oubo_btn.sp_only');
            topBtn.css('bottom', '-100px');
            var showFlag = false;
            //スクロールが0に達したらボタン表示
            $(window).scroll(function () {
                    if ($(this).scrollTop() > 0) {
                            if (showFlag == false) {
                                    showFlag = true;
                                    topBtn.stop().animate({'bottom' : '0px'}, 400);
                            }
                    } else {
                            if (showFlag) {
                                    showFlag = false;
                                    topBtn.stop().animate({'bottom' : '-100px'}, 400);
                            }
                    }
            });
    });
    
    
    // 求人用ハンバーガーメニュー
    $(function() {
        $('.navToggle').click(function() {
            $(this).toggleClass('active');
    
            if ($(this).hasClass('active')) {
                $('#gnav').addClass('active');
                $('body').addClass('active');
            } else {
                $('#gnav').removeClass('active');
                $('body').removeClass('active');
            }
        });
            // メニュー外をタップした時：SP用
            $(document).on('touchstart',function(e) {
                     if(!$(e.target).closest('#nav').length) {
                         // ターゲット要素の外側をタップした時の操作
                $('.navToggle').removeClass('active');
                $('#gnav').removeClass('active');
                $('body').removeClass('active');
    
                     } else {
                         // ターゲット要素をタップした時の操作（今回は不要）
                 }
            });
            // メニュー外をクリックした時：PC用
            $(document).on('click',function(e) {
                     if(!$(e.target).closest('#nav').length) {
                         // ターゲット要素の外側をクリックした時の操作
                $('.navToggle').removeClass('active');
                $('#gnav').removeClass('active');
                $('body').removeClass('active');
    
                     } else {
                         // ターゲット要素をクリックした時の操作（今回は不要）
                 }
            });
    });
    // メニューボタンの挙動
    /*$(function() {
        $('.navToggle').click(function() {
            $(this).toggleClass('active');
    
            if ($(this).hasClass('active')) {
                $('.globalMenuSp').addClass('active');
            } else {
                $('.globalMenuSp').removeClass('active');
            }
        });
    });*/
    
    // 求人用スクロールエフェクト
    var scrollAnimationElm = document.querySelectorAll('.sa');
    var scrollAnimationFunc = function() {
      for(var i = 0; i < scrollAnimationElm.length; i++) {
        var triggerMargin = 150;
        if (window.innerHeight > scrollAnimationElm[i].getBoundingClientRect().top + triggerMargin) {
          scrollAnimationElm[i].classList.add('show');
        }
      }
    }
    window.addEventListener('load', scrollAnimationFunc);
    window.addEventListener('scroll', scrollAnimationFunc);
    
    
    // ページトップへのボタン（スクロール＆出現タイミング）
    $(function() {
        var topBtn = $('#page-top');
        topBtn.hide();
        //スクロールが100に達したらボタン表示
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                topBtn.fadeIn();
            } else {
                topBtn.fadeOut();
            }
        });
        //スクロールしてトップ
        topBtn.click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 500);
            return false;
        });
        // ページ最下部の挙動
        $(window).bind("scroll", function() {
            scrollHeight = $(document).height();
            scrollPosition = $(window).height() + $(window).scrollTop();
            if ( (scrollHeight - scrollPosition) / scrollHeight <= 0.015) {
                //スクロールの位置が下部5%の範囲に来た場合
                $('#page-top').css('transform', 'translateY(-6vh)');
                $('#page-top').css('transition-duration', '0.25s');
            } else {
                //それ以外のスクロールの位置の場合
                $('#page-top').css('transform', 'translateY(0px)');
                $('#page-top').css('transition-duration', '0.25s');
            }
        });
    });
    
    //ページ内スムーススクロール
    $(function(){
       $('a[href^="#"]').click(function() {
          var speed = 400;
          var href= $(this).attr("href");
          var target = $(href == "#" || href == "" ? 'html' : href);
          var position = target.offset().top;
          $('body,html').animate({scrollTop:position}, speed, 'swing');
          return false;
       });
    });
    
    // ローカルナビ（スライダー）
    $(function() {
        $('#localNav').slick({
            dots:true,
            //dots:false,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            centerMode: true,
            responsive: [{
                   breakpoint: 768,
                        settings: {
                             slidesToShow: 3,
                             slidesToScroll: 1,
                   }
              },{
                   breakpoint: 480,
                        settings: {
                             slidesToShow: 1,
                             slidesToScroll: 1,
                        }
                   }
              ]
         });
    });
    
    
    // ashigaruの日常（スライダー）
    $(function() {
        $('.everyday-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            cssEase: 'linear',
            asNavFor: '.everyday-nav',
                    responsive: [{
                   breakpoint: 768,
                        settings: {
                             slidesToShow: 3,
                             slidesToScroll: 1,
                   }
              },{
                   breakpoint: 480,
                        settings: {
                             slidesToShow: 1,
                             slidesToScroll: 1,
                        }
                   }
              ]
         });
        $('.everyday-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.everyday-for',
            autoplay: true,
            autoplaySpeed: 4000,
            dots: false,
            centerMode: true,
            focusOnSelect: true,
                    responsive: [{
                   breakpoint: 768,
                        settings: {
                             slidesToShow: 3,
                             slidesToScroll: 1,
                   }
              },{
                   breakpoint: 480,
                        settings: {
                                                     arrows: false,
                             slidesToShow: 3,
                             slidesToScroll: 1,
                        }
                   }
              ]
         });
    });
    
    
    // 高さを揃える
    $(function() {
        $('#curriculum_flow>li ul.weekly>li').matchHeight();
    });
    

    // GoogleMap
    // var gmg = new google.maps.Geocoder(),
    // map, center, mk ;
    // gmg.geocode({
    //     'address': '大分県大分市中央町2-8−23'
    // }, function(results, status) {
    //     if (status == google.maps.GeocoderStatus.OK) {
    //         center = results[0].geometry.location;
    //         initialize();
    //         mk = new google.maps.Marker({
    //             map: map, position: results[0].geometry.location,
    //             icon: {
    //                 url: '/img/mark.svg',
    //                 anchor: {
    //                     x: 25,
    //                     y: 35
    //                     }},
    //                     title: '株式会社ashigaru'
    //         });
    //     }
    // });

    // function initialize() {
    //     var options = {
    //         center: center,
    //         zoom: 17,
    //         mapTypeId: google.maps.MapTypeId.ROADMAP,
    //         scrollwheel: false,
    //         panControlOptions: {
    //             position: google.maps.ControlPosition.TOP_RIGHT
    //         },
    //         zoomControlOptions: {
    //             position: google.maps.ControlPosition.TOP_RIGHT
    //         },
    //         mapTypeControlOptions: {
    //             position: google.maps.ControlPosition.TOP_CENTER
    //         },
    //         styles: [{
    //             stylers: [{
    //                 hue: "#2985f5"},
    //             {   gamma: 1.50},
    //             {   saturation: -40}]}]
    //     };
    //     map = new google.maps.Map($("#map").get(0), options);
    // }